export const SIZES = {
  SCREEN_MARGIN: '3%',
};

export const DAJ_COLORS = {
  GREEN: '#27B67C',
  SPRING_GREEN: '#14B266',
  WEALTHY_GREEN: '#26B67F',
  RED: '#B62626',
  ARSENIC: '#37414C',
  DARK_GRAY: '#676A7A',
  LIGHT_GRAY: '#C4C4C4',
  NICKEL_GREY: '#707172',
  BLUE: '#0A2E57',
  LIGHT_BLUE: '#0A2E5782',
  NOBLE_BLUE: '#B5D4F6',
  NOBLE_BLUE_400: '#1355A1',
  SKY_BLUE_50: '#ECF5FB',
  SKY_BLUE_100: '#DAECF7',
  SKY_BLUE_400: '#6AB1E1',
  SKY_BLUE_500: '#459ED9',
  GREY: '#ccc',
  WHITE: '#fff',
  OFF_WHITE: '#f6f3f3',
  ALICE_BLUE: '#F1F9FE',
  MAGIC_MINT: '#9FEBCC',
  LAVENDER: '#E6F2FA',
  INFO_BLUE: '#48A0FE',
  MUSTARD_YELLOW: '#F2B23D',
  MUSTARD_YELLOW_300: '#F7D18B',
  MUSTARD_YELLOW_500: '#F2B23D',
  NEUTRAL_GREY: '#F4F6F7',
  NEUTRAL_DARK_GREY: '#AAB5C2',
};

export const FONT = {
  SIZES: {
    F12: '12px',
    F14: '14px',
    F16: '16px',
    F18: '18px',
    F20: '20px',
  },
  WEIGHT: {
    SEMI_BOLD: 600,
    BOLD: 700,
  },
};

export const currencySymbol = {
  usd: '$',
  mxn: '$',
  inr: '₹',
  euro: '€',
  pound: '£',
  aud: '$',
  aed: 'د.إ',
  sgd: '$',
  yen: '¥',
  cad: '$',
  nzd: '$',
  chf: 'CHF',
};

export const IMG_URLS = {
  PROGRESS_BAR_GIF:
    'https://s3-alpha-sig.figma.com/img/26e7/b7a1/f928130a2ede24330c11157b8cff87b1?Expires=1652659200&Signature=CNcW~jcRENkZZjsmRKe--Zwq0gbMdZVnQEXD8rGKz1biJlLUUpRFHGuumCxk~w3HRxzQVXaGhWT~hIg1LmZ9xB0G0QWVpS3C31yRpFCdmBOOFdiVGsMGVNWd0SHM8a4waIWzAGPF-blA596jTue1jIB9ZEZpOIrSlsTGDeJQSJB7ZXbTK4AfIlYSViZ0LyZ1WIGib2HDxaL5GMzwQ2Y~69oqLFOzpueU1aKfTZPuc3DDaX~tjcZE3~dqg47kz0YOvfXI4qcPBxvxrkRwTmLQFvG~OtiljEhlXKywgyFIzIvLASTVVzMgA7sNFZ7aF5LybTAu5TCRDY78yxDsYrod~Q__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
  PROGRESS_IMG_1: require('../assets/A1_revised.gif'),
  PROGRESS_IMG_2: require('../assets/A2_revised.gif'),
  PROGRESS_IMG_3: require('../assets/A3_revised.gif'),
};

export const REGION_CODE_MAP: { [key: string]: string } = {
  INDIA: 'IN',
  MEXICO: 'MX',
  USA: 'US',
  'UNITED STATES OF AMERICA': 'US',
};

export const REGION_LANGUAGE_MAP: {
  [key: string]: { label: string; code: string }[];
} = {
  IN: [
    {
      label: 'english',
      code: 'en-IN',
    },
  ],
  MX: [
    {
      label: 'english',
      code: 'en-MX',
    },
    {
      label: 'spanish',
      code: 'es-MX',
    },
  ],
  US: [
    {
      label: 'english',
      code: 'en-US',
    },
  ],
};

export const DEFAULT_LANGUAGE_MAP: {
  [key: string]: string;
} = {
  IN: 'en-IN',
  MX: 'en-MX',
  US: 'en-US',
};

// TODO: Convert this variable into environment before deploying to staging
// const PORTAL_SCF_DOMAIN = 'https://portal-scf.staging.dripcapital.com';
export const PORTAL_SCF_DOMAIN = process.env.REACT_APP_PORTAL_SCF;

export const CIBIL_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://consumer.cibil.com'
    : 'https://example.com';

export const ADOBE_E_SIGN_URL =
  'https://secure.na2.echosign.com/public/apiesign?pid=CBFCIBAA3AAABLblqZhDs277p92lvnvVTTdSG07o3SxgWBnDWWWWK6WIKIKVeeFHbJXK6gEQWS3b-8eCtF2k%2A';

export const ADOBE_E_SIGN_ORIGIN = 'https://secure.na2.echosign.com';

export const WEETRUST_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://widget.weetrust.mx'
    : 'https://widget-test.weetrust.com.mx';

export const ROLES = {
  shareholder: 'label_shareholder',
  legalRepresentative: 'label_legal_representative',
  principleShareholder: 'label_principle_shareholder',
};

export const CIEC_POLLING_INTERVAL = 15000;

export const CIEC_POLLING_RETRY_COUNT = 8;

export const PAGE_LENGTH = {
  IN: 10,
  US: 10,
  MX: 10,
};

export const MX_BUYER_FORM_DAYS_OPTIONS = ['30', '45', '60', '90', '120'];

export const MAX_ITR_UPLOADS = 4;

export const FILE_SIZES = {
  MB: 1000 * 1000,
};

export const ITR_UPLOAD_FILE_SIZE = 20 * FILE_SIZES.MB;

export const FILE_SIZE = FILE_SIZES.MB;

export const BANK_UPLOAD_FILE_SIZE = 10 * FILE_SIZES.MB;

export const FINANCIALS_UPLOAD_FILE_SIZE = 10 * FILE_SIZES.MB;

export const INCORP_CERTIFICATE_UPLOAD_FILE_SIZE = 50 * FILE_SIZES.MB;

export const BDM = 'BDM';

export const CP = 'CHANNEL_PARTNER';

export const HOME_PATH = '/';

export const USER_ID = 'user_id';

export const TOKEN = 'token';

export const USER_TYPE = 'user_type';

export const USER_TARGET = 'user_target';

export const DRIP_DOMAIN = '.dripcapital.com';

export const COOKIE_PATH = '/';

export const SUPER_USER = 'super_user';

export const CHANNEL_PARTNER = 'channel_partner';

export const GST_FETCH_INTERVAL = 20000;

export const GST_FETCH_LIMIT = 9;

export const CIBIL_FETCH_INTERVAL = 8000;

export const E_SIGN_CHECK_INTERVAL = 10000;

export const IDENTIFICATION_CARD_TYPES: Record<
  'PAN' | 'RFC' | 'IEC' | 'DUN',
  string
> = {
  PAN: 'pan',
  RFC: 'rfc',
  IEC: 'iec',
  DUN: 'duns',
};

export const ONBOARDING_STAGES = {
  PROCESSING: 'Processing',
  CIEC_VALIDATION_SUCCESS: 'CIEC Validation Success',
  OFFER_ACCEPTANCE_PENDING: 'Offer Acceptance Pending',
  COMPANY_INFORMATION_PENDING: 'Company Information Pending',
  FINANCIAL_INFORMATION_PENDING: 'Financial Information Pending',
  BUYER_INFORMATION_PENDING: 'Buyer Information Pending',
  REVIEW_PENDING: 'Review Pending',
  BUSINESS_NOT_REGISTERED: 'business_not_registered',
  APPLICATION: 'application',
  NEW: 'new',
  APPLICANTS: 'applicants',
  USER_DETAILS_PENDING: 'user_details_pending',
  COMPANY_DETAILS_PENDING: 'company_details_pending',
  PERSONAL_INFO_PENDING: 'personal_info_pending',
  FINANCE_INFO_PENDING: 'finance_info_pending',
  BANK_INFO_PENDING: 'bank_info_pending',
  REJECTED: 'rejected',
  LIMIT_APPROVED: 'limit_approved',
  LEGAL_PENDING: 'legal_pending',
  FIRST_INVOICE_PENDING: 'first_invoice_pending',
  FIRST_INVOICE_ADDED: 'first_invoice_added',
  SUSPENDED: 'suspended',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
};

export const ONBOARDING_STAGES_MAP = {
  user_details_pending: 'User Details Pending',
  business_not_registered: 'Business Not Registered',
  company_details_pending: 'Company Info Pending',
  new: 'New',
  personal_info_pending: 'Personal Info Pending',
  bank_info_pending: 'Bank Info Pending',
  finance_info_pending: 'Financial Info Pending',
  review_pending: 'Review Pending',
  processing: 'Limit approval pending',
  limit_approved: 'Limit approved',
  legal_pending: 'Legal pending',
  first_invoice_pending: 'First invoice pending',
  first_invoice_added: 'First invoice added',
  rejected: 'Rejected',
};

export const CHIP_COLOR_STAGE_MAP = {
  processing: 'warning',
  limit_approved: 'success',
  legal_pending: 'info',
  first_invoice_pending: 'info',
  first_invoice_added: 'success',
  rejected: 'error',
};

export const CP_STAGES_MAP = {
  business_not_registered: 'Business not registered',
  processing: 'Application submitted',
  new: 'Lead created',
  active: 'Active',
  cancelled: 'Cancelled',
  rejected: 'Rejected',
  user_details_pending: 'Application in progress',
  company_details_pending: 'Application in progress',
  personal_info_pending: 'Application in progress',
  bank_info_pending: 'Application in progress',
  finance_info_pending: 'Application in progress',
  limit_approved: 'Limit approved',
  legal_pending: 'Legal pending',
  first_invoice_pending: 'First invoice pending',
  first_invoice_added: 'First invoice added',
  review_pending: 'Application in progress',
};

export const CP_STATUS_TEXT = {
  business_not_registered: 'usap_tooltip_text1',
  new: 'usap_tooltip_text4',
  user_details_pending: 'usap_tooltip_text2',
  company_details_pending: 'usap_tooltip_text2',
  personal_info_pending: 'usap_tooltip_text2',
  bank_info_pending: 'usap_tooltip_text2',
  finance_info_pending: 'usap_tooltip_text2',
  processing: 'usap_tooltip_text3',
};

export const AUTH_OPERATION_PATHS = ['getOfferDetails', 'contact'];

export const MAX_FINANCIAL_UPLOADS = 50;

export const MIN_FINANCIAL_UPLOADS = 1;

export const MAX_INCORPORATION_CERTIFICATE_UPLOADS = 5;

export const MIN_INCORPORATION_CERTIFICATE_UPLOADS = 1;

export const MIN_DBA_DOCS_UPLOADS = 1;

export const MAX_BANK_UPLOADS = 50;

export const MIN_ITR_UPLOADS = 1;

export const DEBTOR_TYPE: { [key: string]: string } = {
  SCF: 'US',
};

export const STYLES = {
  textOverflow: {
    wordBreak: 'break-word',
  },
};

export const APP_TYPES = {
  RFP: 'RFP', // Portal RF
  PSCF: 'PSCF', // Portal SCF
  NDAJ: 'NDAJ', // New onboarding journey
  CP: 'CP', // Channel Partners
};

export const BUSINESS_STRUCTURE = {
  SOLE_PROPRIETOR: 'Sole proprietorship',
  PARTNERSHIP: 'Partnership',
  LLC: 'Limited liability company (LLC)',
  C_CORP: 'C-Corporation',
  S_CORP: 'S-Corporation',
  OTHER: 'Other',
};

export const SUPPORTED_FILES = ['pdf', 'jpg', 'jpeg', 'png'];

export const FINANCIAL_DETAILS_CONDITIONS = {
  'Financial Statements (Mandatory)': [
    'Profit and Loss Statements and Balance Sheets for the past two fiscal years (2022 and 2023)',
    'Year-to-date (YTD) 2024 Profit and Loss Statement and Balance Sheet',
  ],
  'Aging Reports (Mandatory)': [
    'Accounts Receivable (AR) aging report',
    'Accounts Payable (AP) aging report',
  ],
  'Additional Documents (Optional)': [
    'Copies of tax returns for the past two years',
    'Audited financial reports (if available)',
  ],
};

export const ON_FIDO_INSTRUCTION_PDF_LINK = process.env.REACT_APP_ONFIDO_PDF;
